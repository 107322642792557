.hidden-listedit {
  .MuiFormLabel-root {
    font-size: 1rem !important;
    font-family: "OpenSans", sans-serif;
  }

  .MuiSelect-outlined {
    padding: 12.5px !important;
    max-height: 15px !important;
  }
  .MuiSelect-selectMenu {
    font-size: 0.7rem;
    font-family: "OpenSans", sans-serif;
  }
  .MuiListItem-button {
    font-size: 0.95rem;
    font-family: "OpenSans", sans-serif;
  }
  .MuiFormLabel-root {
    font-size: 1rem;
    font-family: "OpenSans", sans-serif;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput {
    border-color: #fff !important; //for border color
  }
}

.listedit {
  .MuiFormLabel-root {
    font-size: 1rem !important;
    font-family: "OpenSans", sans-serif;
  }

  .MuiSelect-outlined {
    padding: 12.5px !important;
    max-height: 15px !important;
  }
  .MuiSelect-selectMenu {
    font-size: 0.7rem;
    font-family: "OpenSans", sans-serif;
  }
  .MuiListItem-button {
    font-size: 0.95rem;
    font-family: "OpenSans", sans-serif;
  }
  .MuiFormLabel-root {
    font-size: 1rem;
    font-family: "OpenSans", sans-serif;
  }
  // .MuiOutlinedInput-notchedOutline {
  //   border: none;
  // }
  // .MuiOutlinedInput {
  //   border-color: #fff !important; //for border color
  // }
}

