.file-input {
    display: none;
}

.file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: #4aa1f3;
    font-weight: bold;
    padding: 15px 15px;
    margin-bottom: 5px;
}

.container p {
    color: red;
    text-align: center;
    width: 90%;
}

.drop-container {
    margin: 0;
    // position: absolute;
    // top: 58%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(247, 249, 251, 0.5);
    margin: 0;
    min-width: 90%;
    max-width: 90%;
    height: 250px;
    border: 1px dashed #cdd0cb;
    border-radius: 6px;
}

.upload-icon {
    width: 100px;
    height: 100px;
    background-size: 80%;
    text-align: center;
    margin: 0 auto;
    padding-top: 10px;
}
.uploaded-icon {
    width: 100px;
    height: 100px;
    background-size: 80%;
    text-align: center;
    margin: 0 auto;
    padding-top: 10px;
}

.drop-message {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    color: black;
    padding: 5%;
    font-size: 13px;
    font-weight: 600;
}

.file-progressBar {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
    margin: 0px 10px 5px 5px;
    // vertical-align: top;
    // flex: 6 !important;
    margin-left: 50px;
    box-sizing: content-box;
    // width: calc(100% - 50px);
}

.file-progressBar div {
    // height: auto;
    color: #fff;
    text-align: right;
    line-height: 15px;
    width: 0;
    background-color: #4caf50;
    border-radius: 3px;
    font-size: 13px;
}

.file-display-container {
    // position: absolute;
    width: 90%;
    // text-align: center;f
}

.file-status-bar {
    width: 100%;
    vertical-align: top;
    // margin-top: 17%;
    // margin-left: -10%;
    // position: relative;
    height: 100px;
}

.file-delete-icon {
    color: grey;
    cursor: pointer;
    position: relative;
    top: -70px;
}

.file-status-bar > div {
    overflow: visible;
}

.file-type {
    display: inline-block !important;
    // position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    // margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.file-name {
    display: inline-block;
    position: relative;
    top : -70px;
    color: #33415c;
    font-weight: 700;
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 20%;
    // color: #9aa9bb;
    color: rgba(255, 0, 0, 0.546);
}

.file-error-message {
    color: red;
    display: block;
    // vertical-align: top;
    position: relative;
    top: -60px;
    margin-left: 10%;
    font-weight: 600;
    text-align: center;
}

.file-type-logo {
    width: 50px;
    height: 50px;
    background: url(./generic.png) no-repeat center center;
    background-size: 100%;
    position: absolute;
}

.file-size {
    display: inline-block;
    vertical-align: top;
    color: #30693d;
    margin-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove {
    position: relative;
    top: 10px;
    right: -80%;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
}

.modal {
    z-index: 999;
    display: none;
    overflow: hidden;
}

.modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
}

.modal .modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.modal-image-text {
    position: absolute;
    color: red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.upload-modal {
    z-index: 999;
    display: none;
    overflow: hidden;
}

.upload-modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
}

.progress-container {
    background: white;
    width: 500px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.progress-container span {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
}

.progress {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;
}

.progress-bar {
    position: absolute;
    background-color: #4aa1f3;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.error {
    color: red;
}

.upload-message{
    color:#7D8597 ;
    font-size: 12px;
}