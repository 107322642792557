.App {
    text-align: center;
}

* {
    font-family: "OpenSans", sans-serif !important;
    font-size: 13px;
    box-sizing: border-box;
    caret-color: transparent !important;
}
input, textarea {
    caret-color: auto !important; /* Show the cursor for input and textarea */
}

body {
    background-color: #f7f9fb !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    // margin-right: 1px;
}

/* Track */
::-webkit-scrollbar-track {
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #979dac;
    border-radius: 5px;
}
