.default {
  color: #979dac !important;
}

.primary {
  color: #0073e6 !important;
}

.borderPrimary {
  border-left: 3px solid #0073e6 !important;
}

.textRight {
  text-align: right;
}

.fonts {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
}

.notifPadding{
  padding: 0.6rem;
}