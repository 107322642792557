.popup{
  z-index: 1400 !important;
  position: relative;
}

.btn-white {
  color: #ffffff !important; 
  /* Set the text color to white */
}

.swal2-container {
  display: flex;
  position: fixed;
  z-index: 1500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 13;
  background: initial;
  background-color: #2196f3;
  color: #fff;
  font-size: 1.0625em;
}