.dotcolor {
  height: 7px;
  width: 7px;
  background-color: rgba(51, 65, 92, 0.4);
  border-radius: 50%;
  display: inline-block;
  margin: 0px 2px;
  cursor: pointer;
}

.dotgrey {
  background-color: rgba(51, 65, 92, 1);
}

.carousel {
  transition: ease-in;
}
