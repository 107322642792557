.not-active-button {
    color: #7d8597 !important;
    background-color: #f1f1f1 !important;
}

.active-button {
    color: #f8f8f8 !important;
    background-color: #0073e6 !important;
    box-shadow: 0px 4px 8px rgba(2, 62, 125, 0.2);
}
