.filled {
    padding: 6px;
    width: 80%;
    background-color: #0073e6 !important;
    color: white !important;
    cursor: pointer;
}

.filled:hover {
    background-color: #0367ca !important;
}

.outlined {
    padding: 6px;
    width: 80%;
    background-color: white !important;
    color: #0073e6 !important;
    border: 1px solid #0073e6;
    cursor: pointer;
}
.outlined_red {
    padding: 6px;
    width: 80%;
    background-color: white !important;
    color: #e01e37 !important;
    border: 1px solid #e01e37;
    cursor: pointer;
}

.transparent {
    padding: 6px;
    background-color: #f7f9fb !important;
    color: #0073e6 !important;
    border: 1px solid #0073e6;
    cursor: pointer;
}
.outlined:hover {
    background-color: #f2f2f3 !important;
}
.transparent:hover {
    background-color: #f2f2f3 !important;
}
